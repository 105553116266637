import {
  trackJobFormInitiated,
  trackJobFormExited,
  trackJobFormCompleted,
  trackJobFormQuestionCompleted,
  trackJobFormQuestionInitiated,
  type TrackJobFormInitiatedOptions,
  type TrackJobFormExitedOptions,
  type TrackJobFormCompletedOptions,
  type TrackJobFormQuestionInitiatedOptions,
  type TrackJobFormQuestionCompletedOptions
} from '@oneflare/web-analytics';
import cloneDeep from 'lodash/cloneDeep';
import debounce from 'lodash/debounce';
import isEmpty from 'lodash/isEmpty';
import omit from 'lodash/omit';
import pick from 'lodash/pick';
import uniq from 'lodash/uniq';
import dynamic from 'next/dynamic';
import type { ComponentType } from 'react';
import { Component } from 'react';

import { OneflareAnalytics } from 'lib/analytics/oneflareAnalytics';
import { DataDogRumAgent } from 'lib/datadog/initializeDatadog';
import {
  CATEGORY_QUESTION_ID,
  CONTACT_DETAILS_ID,
  EMAIL_QUESTION_ID,
  GENERAL_QUESTION_IDS,
  JOB_FORM_CATEGORY_ID,
  JOB_FORM_OPEN,
  LOCATION_QUESTION_ID,
  MOBILE_VERIFICATION_ID,
  MOBILE_VERIFICATION_QUESTION
} from 'lib/oneflare-job-form/utils/constants';
import { JobFormControllerContext, intialJobFormControllerState } from 'lib/oneflare-job-form/utils/context';
import jobFormHelper from 'lib/oneflare-job-form/utils/jobFormHelper';
import JobFormService from 'lib/oneflare-job-form/utils/JobFormService';
import TrackingHelper from 'lib/oneflare-job-form/utils/trackingHelper';
import { getDomain, getJobFormUrl } from 'lib/utils/Environment';
import type { CreateJobReponse } from 'mutations/shared/jobForm';
import { isNestedObject, isValidURL } from 'shared/utils/helpers';
import type * as jobFormTypes from 'types/oneflare.com.au/jobForm';

const JobForm = dynamic(() => import('lib/oneflare-job-form/JobForm'), { ssr: false });
const DOMAIN = getDomain();
const JOB_FORM_URL = getJobFormUrl();

const withJobFormController = (
  WrappedComponent: ComponentType<Record<string, unknown>>
) => {
  return class JobFormController extends Component<
    jobFormTypes.IJobFormControllerProps,
    jobFormTypes.JobFormState
  > implements jobFormTypes.IJobFormController {
    constructor(props) {
      super(props);
      this.DOMAIN = DOMAIN;
      this.state = intialJobFormControllerState(DOMAIN);
    }

    async componentDidMount() {
      const {
        originalReferer: ogReferrer,
        pageProps
      } = this.props;
      const categoryId = pageProps?.categoryId;
      const ogRefererPageProps = pageProps?.originalReferer;
      const originalReferer = ogReferrer ?? ogRefererPageProps;
      const validUrl = isValidURL(originalReferer) ? new URL(originalReferer).origin : 'https://www.oneflare.com.au/';
      // with ssr and client side generated pages tracker assignment happens
      // when component gets mounted as original referrer is already available on server side
      this.trackingHelper = new TrackingHelper({
        answerValue: '',
        categoryId,
        formStep: '',
        jobId: null,
        label: '',
        locationId: null,
        originalReferer: validUrl,
        questionId: null,
        serviceTypeId: []
      });

      // initialize service on client side to have access to apollo cache
      this.jobFormService = new JobFormService();

      // track job form landing
      this.trackingHelper.trackJobFormLanding({ categoryId });
      // get current user and initialize questions and answers
      const currentUser = await this.jobFormService.getCurrentUser();
      if (currentUser) {
        this.oneflareAnalytics = new OneflareAnalytics(String(currentUser.user.id));
        this.setState({ currentUser });
        this.state.isLoggedIn.next(true);
      } else {
        this.oneflareAnalytics = new OneflareAnalytics();
      }
      await this.initializeQuestionsAndAnswers();
    }

    handleSetLocationAnswer = ({ locationId, locationName }: jobFormTypes.IHandleSetLocationAnswer) => {
      const { questions$, userAnswers } = this.state;
      const userAnswersCopy = { ...userAnswers.value };
      if (userAnswersCopy[LOCATION_QUESTION_ID]) {
        userAnswersCopy[LOCATION_QUESTION_ID].userAnswer = {
          value: locationId,
          name: locationName
        } as jobFormTypes.UserAnswer;
      }
      userAnswers.next(userAnswersCopy);
      if (locationId) {
        const nextQuestions = questions$.value.filter(({ id }) => id !== LOCATION_QUESTION_ID);
        questions$.next(nextQuestions);
      }
    };

    handleSendMobileCode = async (email: string, isResendRequest: boolean) => {
      try {
        const sendMobileCodeData = await this.jobFormService.sendMobileCode(email);
        this.setState({ sendMobileCodeData });
        if (isResendRequest && sendMobileCodeData?.status === 'success') {
          this.trackingHelper.trackInteraction('resendCodeSuccess');
        }
      } catch (exception) {
        this.setState({ sendMobileCodeData: null });
        this.handleUserFeedback(exception);
      }
    };

    handleVerifyMobileCode = async (token: string): Promise<boolean> => {
      const { jobFormFailureListener$, isLoading$ } = this.state;
      this.setState({ isVerifyCodeLoading: true });
      isLoading$.next(true);
      jobFormFailureListener$.next({ failed: false });
      const { createPendingJobAttrs: { email }} = this.state;
      try {
        const verifyMobileCodeData = await this.jobFormService.verifyMobileCode( email, token );
        this.setState({ isVerifyCodeLoading: false });
        if (!verifyMobileCodeData) return false;
        this.setState({ verifyMobileCodeData });
        const { status } = verifyMobileCodeData;
        const successfullyVerified = status === 'success';
        if (successfullyVerified) {
          await this.handleCreateJob({ questionId: MOBILE_VERIFICATION_ID });
        }
        isLoading$.next(false);
        return successfullyVerified;
      } catch (exception) {
        isLoading$.next(false);
        this.handleUserFeedback(exception);
        DataDogRumAgent.addRumError(exception, 'Oneflare | HOC withJobFormController | VERIFY_MOBILE_CODE mutation');
        return false;
      }
    };

    handleSendEmailLink = async () => {
      const { pendingJobUuid$, isEmailLinkSent$ } = this.state;
      let returnedPendingJobId;
      try {
        this.setState({ isEmailLinkLoading: true });
        returnedPendingJobId = await this.jobFormService.sendEmailLink(pendingJobUuid$.value);
      } catch (exception) {
        this.handleUserFeedback(exception);
      }

      this.setState({ isEmailLinkLoading: false });
      if (returnedPendingJobId) {
        isEmailLinkSent$.next(true);
      }

      this.trackingHelper.trackInteraction('sendMagicLink');
    };

    handleResetPassword = async () => {
      const { createPendingJobAttrs: { email }, resetPasswordEmailRequestSent$, resetPasswordEmailRequestLoading$ } = this.state;
      resetPasswordEmailRequestLoading$.next(true);
      try {
        const result = await this.jobFormService.resetPassword(email);
        resetPasswordEmailRequestSent$.next(result);
      } catch (exception) {
        this.handleUserFeedback(exception);
        DataDogRumAgent.addRumError(exception, 'Oneflare | HOC withJobFormController | handleResetPassword method');
      }
      resetPasswordEmailRequestLoading$.next(false);
    };

    open = async ({
      categoryId,
      postcode,
      locationId = 0,
      locationName,
      page,
      section,
      ctaText,
      categoryName
    }: jobFormTypes.JobFormOpenArgs = {}) => {
      const { openJobForm$ } = this.state;
      DataDogRumAgent.addCustomKey(JOB_FORM_OPEN, true);
      this.trackingHelper.trackJobFormLanding({ categoryId, locationId });
      if (categoryId) {
        DataDogRumAgent.addCustomKey(JOB_FORM_CATEGORY_ID, categoryId);

        await this.initializeQuestionsAndAnswers(categoryId, postcode).then(() => {
          if (locationId && locationId !== 0) {
            this.handleSetLocationAnswer({
              locationId: String(locationId),
              locationName
            });
          }
        });
      }

      this.setState({
        jobFormCity: locationName,
        jobFormCategoryName: categoryName,
        jobFormCategoryId: Number(categoryId),
        jobFormPostcode: postcode,
        jobFormIniatedSection: section,
        jobFormInitiatedPage: page,
        jobFormInitiatedCtaText: ctaText
      });

      const trackInitiatedOptions = {
        initiatedPage: page,
        initiatedSection: section,
        initiatedCtaText: ctaText,
        isLoggedIn: this.state.isLoggedIn.value,
        jobFormCategoryName: categoryName,
        jobFormCategoryId: categoryId,
        jobFormPostcode: postcode,
        jobFormCity: locationName
      } as TrackJobFormInitiatedOptions;

      trackJobFormInitiated(this.oneflareAnalytics, 'withJobFormController:open', trackInitiatedOptions);
      openJobForm$.next(true);
    };

    close = () => {
      let initialState = intialJobFormControllerState(this.DOMAIN);
      const { currentUser } = this.state;
      if (!isEmpty(currentUser)) {
        initialState = omit(initialState, ['currentUser', 'isLoggedIn']);
      }
      // Reset state except currentUser
      this.setState(
        initialState,
        // Initialize questions and userAnswers in setState callback
        this.initializeQuestionsAndAnswers
      );

      trackJobFormExited(this.oneflareAnalytics, 'withJobFormController:close', {
        questionId: this.state.currentQuestionIdForTracking,
        questionText: this.state.currrentQuestionTextForTracking,
        jobFormCategoryId: this.state.jobFormCategoryId,
        jobFormPostcode: this.state.jobFormPostcode,
        jobFormCity: this.state.jobFormCity,
        jobFormCategoryName: this.state.jobFormCategoryName,
        isLoggedIn: this.state.isLoggedIn.value
      } as TrackJobFormExitedOptions);
      DataDogRumAgent.removeCustomKey(JOB_FORM_OPEN);
      DataDogRumAgent.removeCustomKey(JOB_FORM_CATEGORY_ID);
    };

    goBack = () => {
      const { isEmailLinkSent$, showLoginWithPasswordStep$, resetPasswordEmailRequestSent$ } = this.state;
      isEmailLinkSent$.next(false);
      showLoginWithPasswordStep$.next(false);
      resetPasswordEmailRequestSent$.next(false);
    };

    setPasswordError = (error: string) => {
      this.setState({ passwordError: error });
    };

    updateState = (newState: Partial<jobFormTypes.JobFormState>) => {
      this.setState(newState as jobFormTypes.JobFormState);
    };

    goToNextStep = async ({
      inputType,
      questionId,
      userAnswer,
      hasNextStep,
      clusterType,
      clusteredById,
      clusterQuestion,
      clusteredQuestions,
      clusteredUserAnswers
    }: jobFormTypes.GoToNextStepArgs) => {
      const { questions$, userAnswers, descriptionQuestionId, createPendingJobAttrs } = this.state;
      const [answerValue, serviceTypeIds] = jobFormHelper.formatUserAnswer(userAnswer, inputType);
      const serviceTypeId = uniq(serviceTypeIds);

      const redirectToNextUrl = (url: string) => {
        if (url) {
          window.location.href = url;
        }
      };

      // check answer is from checkboxes
      if (isNestedObject(userAnswer)) {
        const mappedData = Object.values(userAnswer as jobFormTypes.CheckboxUserAnswer).filter((item) => item?.checked && item?.nextUrl);

        if (mappedData.length && mappedData[0]?.nextUrl) {
          redirectToNextUrl(mappedData[0].nextUrl);
        }
      } else {
        redirectToNextUrl(userAnswer?.nextUrl);
      }

      // Question with id '2' gets pre-filled with state name from answer to location question
      const hasStateQuestion = questions$.value.some((question) => question.id === '2');
      const goToNextQuestion = () => this.traverseJobForm({
        answerValue: answerValue as string,
        clusteredById,
        clusteredQuestions,
        clusteredUserAnswers,
        clusterQuestion,
        clusterType,
        descriptionQuestionId,
        inputType,
        questionId,
        userAnswer,
        serviceTypeId
      });

      switch (true) {
        case CATEGORY_QUESTION_ID === questionId:
          DataDogRumAgent.addCustomKey(JOB_FORM_CATEGORY_ID, +userAnswer?.value);

          goToNextQuestion();
          break;
        case LOCATION_QUESTION_ID === questionId && hasStateQuestion: {
          const stateName = jobFormHelper.getGeographicalState(userAnswer.name);
          userAnswers.next({
            ...userAnswers.value,
            2: {
              ...userAnswers.value['2'],
              userAnswer: { serviceTypeId: null, value: stateName }
            }
          });
          goToNextQuestion();
          break;
        }
        case EMAIL_QUESTION_ID === questionId: {
          const { jobFormFailureListener$ } = this.state;
          jobFormFailureListener$.next({ failed: false });
          try {
            const { validEmail, emailExists, phoneExists, firstName, mobileVerificationRequired } = await this.jobFormService.validateEmail(userAnswer);
            this.setState({ emailExists, phoneExists, mobileVerificationRequired, firstName });

            if (!validEmail && !emailExists) {
              const { emailState } = this.state;
              emailState.next({ errorMessage: 'Please enter a valid email address' });
              break;
            }

            await this.handleCreatePendingJob({
              descriptionQuestionId,
              userAnswer,
              userAnswers,
              emailExists,
              phoneExists
            }).then(() => {
              goToNextQuestion();
            });
          } catch (exception) {
            this.handleUserFeedback(exception);
            DataDogRumAgent.addRumError(exception, 'Oneflare | HOC withJobFormController | CREATE_PENDING_JOB mutation');
          }
          break;
        }
        case CONTACT_DETAILS_ID === questionId: {
          const { name, phone } = userAnswer;
          await this.handleCreateUserWithPendingJob({ questionId, name, phone, goToNextQuestion });
          break;
        }
        case MOBILE_VERIFICATION_ID === questionId: {
          const { showLoginWithPasswordStep$ } = this.state;
          if (showLoginWithPasswordStep$.value) {
            try {
              const { email } = createPendingJobAttrs;
              const userId = await this.jobFormService.logInWithPassword({ email, password: userAnswer });
              if (!userId || typeof userId == 'undefined') {
                this.setPasswordError('The password you have entered is incorrect. Please try again.');
              } else {
                await this.handleCreateJob({ questionId });
              }
            } catch (exception) {
              this.handleUserFeedback(exception);
            }
          }
          break;
        }
        case questionId === descriptionQuestionId:
          // if the user is on the description step and there are no more steps
          // we can assume the user is logged in
          if (!hasNextStep) {
            await this.handleLoggedInJobCreation({
              userAnswer,
              questionId,
              clusterType,
              answerValue: answerValue as string,
              userAnswers,
              serviceTypeId,
              clusterQuestion,
              clusteredUserAnswers,
              descriptionQuestionId
            });
          } else {
            goToNextQuestion();
          }
          break;
        default:
          goToNextQuestion();
          break;
      }
    };

    goToPreviousStep = ({
      questionId,
      userAnswer,
      inputType,
      clusteredById,
      clusterQuestion,
      clusteredUserAnswers
    }: jobFormTypes.GoToPreviousStepArgs) => {
      const { currentStep, attachments, overrideShowBackButton$ } = this.state;
      if (overrideShowBackButton$.value) {
        overrideShowBackButton$.next(false);
      }
      this.setUserAnswers({
        questionId,
        userAnswer,
        inputType,
        attachments,
        clusteredById,
        clusterQuestion,
        clusteredUserAnswers
      });
      currentStep.next(currentStep.value - 1);
    };

    private initializeQuestionsAndAnswers = async (categoryId?: string | number, postcode?: string) => {
      const { pageProps } = this.props;

      const { currentUser, originalQuestions, questions$, userAnswers } = this.state;
      const categoryIdFromProps = pageProps?.categoryId;
      const postCodeFromProps = pageProps?.postcode;
      const isLoggedIn = Boolean(currentUser);
      const initialPostcode = postcode ?? postCodeFromProps;
      const initialCategoryId = categoryId ?? categoryIdFromProps;
      const [initialQuestions, descriptionQuestionId] = await this.jobFormService.getQuestions(
        initialCategoryId,
        isLoggedIn,
        'initial'
      );
      const initialCategoryQuestionAnswer =  { name: '', value: initialCategoryId ?? '' };
      const initialLocationQuestionAnswer = { name: initialPostcode ?? '', value: '' };
      const initializedUserAnswers = jobFormHelper.initializeAllUserAnswers(
        initialCategoryQuestionAnswer,
        initialLocationQuestionAnswer,
        initialQuestions
      );
      const filteredQuestions = jobFormHelper.filterQuestions(
        initialQuestions,
        initializedUserAnswers
      );
      this.setState({ descriptionQuestionId });
      originalQuestions.next(initialQuestions);
      questions$.next(filteredQuestions);
      userAnswers.next(initializedUserAnswers);
      return 'questions retrieved';
    };

    private setUserAnswers = ({
      questionId,
      userAnswer,
      inputType,
      attachments,
      clusteredById,
      clusterQuestion,
      clusteredUserAnswers
    }: jobFormTypes.SetUserAnswersArgs, callback?: CallableFunction) => {
      const { originalQuestions, questions$, userAnswers } = this.state;
      const currentAnswer = userAnswer?.value;
      const previousAnswer = (userAnswers.value[questionId]?.userAnswer as jobFormTypes.UserAnswer)?.value;
      if (questionId === CATEGORY_QUESTION_ID && currentAnswer !== previousAnswer) {
        // If user goes back to cateogry question to make change
        // after having answered some questions, userAnswers needs to be reset.
        const unsetLocationAnswer = { name: '', value: '' };
        const initialJobFormQuestionsOnReset = originalQuestions.value;
        const initializedUserAnswers = jobFormHelper.initializeAllUserAnswers(
          userAnswer,
          unsetLocationAnswer,
          initialJobFormQuestionsOnReset
        );
        const filteredQuestions = jobFormHelper.filterQuestions(
          initialJobFormQuestionsOnReset,
          initializedUserAnswers
        );
        questions$.next(filteredQuestions);
        userAnswers.next(initializedUserAnswers);
      } else {
        userAnswers.next({
          ...userAnswers.value,
          ...clusteredUserAnswers,
          [questionId]: {
            attachments,
            clusteredById,
            clusterQuestion,
            inputType,
            userAnswer
          }
        });
      }
      if (callback) callback();
    };

    private traverseJobForm = async ({
      answerValue,
      clusteredById,
      clusteredQuestions,
      clusteredUserAnswers,
      clusterQuestion,
      clusterType,
      descriptionQuestionId,
      inputType,
      questionId,
      userAnswer,
      serviceTypeId
    }: jobFormTypes.TraverseJobFormArguments) => {
      const { currentStep, attachments } = this.state;
      const step = currentStep.value;
      await this.setFollowUpQuestions({
        clusteredQuestions,
        clusteredUserAnswers,
        clusterQuestion,
        inputType,
        questionId,
        step,
        userAnswer
      });

      this.setUserAnswers({
        questionId,
        userAnswer,
        inputType,
        attachments,
        clusteredById,
        clusterQuestion,
        clusteredUserAnswers
      }, () => {
        const { userAnswers } = this.state;
        const categoryId = (userAnswers.value[CATEGORY_QUESTION_ID].userAnswer as jobFormTypes.UserAnswer).value;
        const locationId = (userAnswers.value[LOCATION_QUESTION_ID]?.userAnswer as jobFormTypes.UserAnswer).value;

        if (step === 0) {
          this.trackingHelper.trackJobFormStart({ categoryId, locationId, questionId });
        }
        this.trackingHelper.trackJobFormAnswer({
          step,
          answerValue,
          categoryId,
          locationId,
          questionId,
          userAnswer,
          clusterType,
          serviceTypeId,
          clusterQuestion,
          clusteredUserAnswers,
          descriptionQuestionId
        });
        const answerText = TrackingHelper.getAnswerValueForTracking(answerValue, questionId, descriptionQuestionId, true, true);
        trackJobFormQuestionCompleted(this.oneflareAnalytics, 'withJobFormController:traverseJobForm', {
          jobFormCategoryId: +categoryId,
          jobFormCategoryName: this.state.jobFormCategoryName,
          questionId: +this.state.currentQuestionIdForTracking,
          answerText,
          jobFormStep: step
        } as TrackJobFormQuestionCompletedOptions);

        // increase step to automatically move to next question
        currentStep.next(step + 1);
      });
    };

    private setFollowUpQuestions = async ({
      clusteredQuestions,
      clusteredUserAnswers,
      clusterQuestion,
      inputType,
      questionId,
      step,
      userAnswer
    }: jobFormTypes.SetJobFormQuestionsArgs) => {
      const { emailExists, originalQuestions, questions$, overrideShowBackButton$, mobileVerificationRequired } = this.state;
      /**
       * These are questions that are static and declared on Fronted; not coming from backennd
       * i.e. Description, Contact details, Mobile verification, Email, Location, Category
       */
      const notGeneralQuestion = !GENERAL_QUESTION_IDS.includes(questionId);
      switch (true) {
        case questionId === CATEGORY_QUESTION_ID: {
          const { currentUser } = this.state;
          const isLoggedIn = Boolean(currentUser);
          const [nextQuestions, descriptionQuestionId] = await this.jobFormService.getQuestions(userAnswer.value, isLoggedIn);
          this.setState({ descriptionQuestionId });
          originalQuestions.next(nextQuestions);
          break;
        }
        case questionId === EMAIL_QUESTION_ID && emailExists: {
          // If email exists, replace default question Contact details with Mobile verification
          const nextQuestions = questions$.value.map((item) =>
            item.id === CONTACT_DETAILS_ID
              ? MOBILE_VERIFICATION_QUESTION
                : item);
            questions$.next(nextQuestions);
          break;
        }
        case questionId === CONTACT_DETAILS_ID && mobileVerificationRequired: {
          // If current step is the contact details step and mobile verification is required, replace default question Contact details with Mobile verification
          const nextQuestions = [...questions$.value, MOBILE_VERIFICATION_QUESTION];
          overrideShowBackButton$.next(true);
          questions$.next(nextQuestions);
          break;
        }
        case notGeneralQuestion: {
          // Set next question based on user answer, which includes `nextQuestionId`
          const nextQuestion = jobFormHelper.getNextQuestion({
            clusteredQuestions,
            clusteredUserAnswers,
            clusterQuestion,
            nonClusteredInputType: inputType,
            nonClusteredUserAnswer: userAnswer,
            questions: originalQuestions.value
          });

          if (!nextQuestion) {
            const questionsAnswered = questions$.value.slice(0, step + 1);
            const questionsLeft = questions$.value.slice(step + 1);
            const generalQuestionsLeftAndClusteredQuestions = questionsLeft.filter(
              ({ id, clusteredById }) => GENERAL_QUESTION_IDS.includes(String(id)) || clusteredById
            );
            questions$.next([
              ...questionsAnswered,
              ...generalQuestionsLeftAndClusteredQuestions
            ]);
            break;
          }
          const questionsCopy = [...questions$.value];
          const nextQuestionIsAGeneralQuestion = GENERAL_QUESTION_IDS.includes(`${questionsCopy[step + 1].id}`);
          let deleteCount = 1;
          if (nextQuestionIsAGeneralQuestion) {
            deleteCount = 0;
          }
          questionsCopy.splice(step + 1, deleteCount, nextQuestion);
          questions$.next(questionsCopy);
          break;
        }
        default:
          break;
      }
    };

    private handleCreateUserWithPendingJob = debounce(async ({
      name,
      phone,
      goToNextQuestion
    }: jobFormTypes.HandleCreateUserWithJobArgs) => {
      const { marketingConsentConfirmed, pendingJobUuid$, jobFormFailureListener$, isLoading$, userCreated } = this.state;
      isLoading$.next(true);
      this.setState({ fullName: name, phone, marketingConsentConfirmed });
      jobFormFailureListener$.next({ failed: false });
      try {
        const userArguments = {
          name,
          pendingJobUuid: pendingJobUuid$.value,
          marketingConsentConfirmed
        };

        let response: { jobId: number; redirectUrl: string; mobileVerificationRequired: boolean } | {
          pendingJobUuid: string;
          mobileVerificationRequired: boolean;
        };


        // handles updating a new user if a user is coming back from the mobile verification step
        if (userCreated) {
          const { email } = this.state.createPendingJobAttrs;
          response = await this.jobFormService.updatePendingJob({ ...userArguments, email, mobile: phone }) as {
            pendingJobUuid: string;
            mobileVerificationRequired: boolean;
          };
        } else {
        // handles creating a new user if a user is coming from the contact details step
          response = await this.jobFormService.createUserWithPendingJob({ ...userArguments, phone});
        }

        // Redirect to My Jobs & auto log in
        if (response.mobileVerificationRequired) {
          const { email } = this.state.createPendingJobAttrs;
          this.setState({ mobileVerificationRequired: true, userCreated: true });
          await this.handleSendMobileCode(email, false);
          this.trackingHelper.trackInteraction('mobileVerificationRequired');
          isLoading$.next(false);
          goToNextQuestion();
          return;
        }

        if ('jobId' in response) {
          const jobId = response.jobId;
          const redirectUrl = response.redirectUrl ? `${JOB_FORM_URL}${response.redirectUrl}` : null;
          this.handlePostComplete(jobId, redirectUrl, CONTACT_DETAILS_ID);
        }
      } catch (exception) {
        isLoading$.next(false);
        this.handleUserFeedback(exception);
        DataDogRumAgent.addRumError(exception, 'Oneflare | HOC withJobFormController | CREATE_JOB mutation');
      }
    }, 300);

    private handleCreateJob = debounce(async ({
      questionId,
      jobData
    }: jobFormTypes.HandleCreateJobArgs) => {
      const { isLoading$, currentUser, userAnswers, pendingJobUuid$, jobFormFailureListener$, createPendingJobAttrs } = this.state;
      // set loading state to true
      isLoading$.next(true);

      // Reset `jobFormFailureListener$` in case previous attempt fails
      jobFormFailureListener$.next({failed: false });

      try {
        const categoryId = (userAnswers.value[CATEGORY_QUESTION_ID].userAnswer as jobFormTypes.UserAnswer).value ?? createPendingJobAttrs.categoryId;
        const createJobVariables = {} as jobFormTypes.CreateJobAttrs;
        // if logged in, use user from context
        if (!isEmpty(currentUser?.user)) {
          createJobVariables.user = pick(currentUser.user, ['email', 'name', 'phone']);
          createJobVariables.jobData = jobData;
          createJobVariables.categoryId = +categoryId;
        } else {
          const { email, jobData } = createPendingJobAttrs;
          const { firstName: name, phone } = this.state;
          createJobVariables.pendingJobUuid = pendingJobUuid$.value;
          createJobVariables.categoryId = +categoryId;
          createJobVariables.jobData = jobData;
          createJobVariables.user = {
            email,
            ...(name && { name }),
            ...(phone && { phone })
          };
        }
        const response = await this.jobFormService.createJob(createJobVariables);
        const { createJob } = cloneDeep(response) as CreateJobReponse;
        const jobId = createJob.id;
        const redirectUrl = createJob.redirectUrl;
        this.handlePostComplete(jobId, redirectUrl, questionId);
      } catch (exception) {
        isLoading$.next(false);
        this.handleUserFeedback(exception);
        DataDogRumAgent.addRumError(exception, 'Oneflare | HOC withJobFormController | CREATE_JOB mutation');
      }
    }, 300);

    private handleCreatePendingJob = async ({
      descriptionQuestionId,
      userAnswer,
      emailExists = false,
      phoneExists = false,
      userAnswers
    }: {
      descriptionQuestionId: string;
      userAnswer: jobFormTypes.InputComponentUserAnswer;
      emailExists?: boolean;
      phoneExists?: boolean;
      userAnswers: jobFormTypes.BehaviorSubjectUserAnswers;
    }) => {
      const { isLoading$, pendingJobUuid$, questions$ } = this.state;
      isLoading$.next(true);
      // Create pending job
      const formattedUserAnswersAsPendingJobAttrs = jobFormHelper.formatAllUserAnswers({
        descriptionQuestionId,
        email: userAnswer as string,
        questions: questions$.value,
        userAnswers: userAnswers.value
      });
      const { pendingJobUuid } = await this.jobFormService.createPendingJob(formattedUserAnswersAsPendingJobAttrs);
      pendingJobUuid$.next(pendingJobUuid);
      this.setState({ createPendingJobAttrs: formattedUserAnswersAsPendingJobAttrs });
      // send mobile verification code if existing user
      if (emailExists && phoneExists) {
        await this.handleSendMobileCode(userAnswer as string, false);
      }
      isLoading$.next(false);
    };

    validatePhone = async (phone: string) => {
      try {
        const { valid, message } = await this.jobFormService.validatePhone(phone);
        return { valid, message };
      } catch (exception) {
        DataDogRumAgent.addRumError(exception, 'Oneflare | HOC withJobFormController | validatePhone mutation');
        return { valid: false, message: exception.message || 'Something went wrong! Please try again later.' };
      }
    };

    private handleUserFeedback = (exception: Error) => {
      const { jobFormFailureListener$ } = this.state;
      switch (true) {
        case exception instanceof TypeError:
          jobFormFailureListener$.next({ failed: true, message: 'Something went wrong processing your job request. Please try again in a few minutes or try refreshing your browser.' });
          break;
        default:
          jobFormFailureListener$.next({ failed: true, message: exception.message });
          break;
      }
    };

    private handlePostComplete = (jobId: number, redirectUrl: string, questionId: string) => {
      if (!jobId && !redirectUrl) throw TypeError('jobId and redirectUrl are missing');
      const { postedJob$, createPendingJobAttrs, userAnswers, external, currentStep, showSuccessScreen$ } = this.state;
      const { redirectOnSuccess, isIframe } = external.value;
      const step = currentStep.value;
      const categoryId = (userAnswers.value[CATEGORY_QUESTION_ID].userAnswer as jobFormTypes.UserAnswer).value as number ?? createPendingJobAttrs.categoryId;
      const locationId = (userAnswers.value[LOCATION_QUESTION_ID].userAnswer as jobFormTypes.UserAnswer).value;
      const trackingArguments = {
        step,
        jobId,
        categoryId,
        questionId,
        locationId,
        createPendingJobAttrs
      };

      this.trackFormComplete(trackingArguments);
      if (isIframe) {
        if (redirectOnSuccess) {
          window.parent.location.replace(redirectUrl);
          return;
        }
        // this is purely for iframe use case where we display a success screen as the last step
        postedJob$.next({ id: jobId, redirectUrl });
        showSuccessScreen$.next(true);
        return;
      }
      window.location.replace(redirectUrl);
    };

    private handleLoggedInJobCreation = async ({
      userAnswer,
      questionId,
      clusterType,
      answerValue,
      userAnswers,
      serviceTypeId,
      clusterQuestion,
      clusteredUserAnswers,
      descriptionQuestionId
    }: jobFormTypes.HandleLoggedInJobCreationArgs) => {
      const { questions$, currentStep, currentUser: { user }, attachments } = this.state;
      const step = currentStep.value;
      const { email } = user;

      const formattedUserAnswers = jobFormHelper.formatAllUserAnswers({
        email,
        descriptionQuestionId,
        questions: questions$.value,
        userAnswers: userAnswers.value
      });

      const { categoryId, jobData } = formattedUserAnswers;
      const locationId = (
        userAnswers.value[LOCATION_QUESTION_ID]?.userAnswer as jobFormTypes.UserAnswer
      ).value;

      let formattedAttachments = [];

      if (!isEmpty(attachments)) {
        formattedAttachments = attachments.map(({ thumb }) => thumb);
      }

      jobData.job.attachments = formattedAttachments;
      jobData.job.description = userAnswer.value;

      this.trackingHelper.trackJobFormAnswer({
        step,
        answerValue,
        categoryId,
        clusterType,
        userAnswer,
        locationId,
        questionId,
        serviceTypeId,
        clusterQuestion,
        clusteredUserAnswers,
        descriptionQuestionId
      });

      await this.handleCreateJob({ questionId, jobData });
    };

    private trackFormComplete = (jobAttributes: jobFormTypes.TrackCompleteArgs) => {
      // segment tracking
      trackJobFormCompleted(this.oneflareAnalytics, 'withJobFormController:trackFormComplete', {
        isLoggedIn: this.state.isLoggedIn.value,
        jobId: jobAttributes.jobId.toString(),
        jobFormCategoryId: jobAttributes.categoryId,
        jobFormCategoryName: this.state.jobFormCategoryName,
        jobFormPostcode: this.state.jobFormPostcode,
        jobFormCity: this.state.jobFormCity
      } as TrackJobFormCompletedOptions);

      // snowplow tracking
      this.trackingHelper.trackJobFormComplete({...jobAttributes });
    };

    trackQuestionInitiated = ({
      questionId,
      questionText,
      jobFormStep
    }) => {
      this.setState({
        currentQuestionIdForTracking: questionId,
        currrentQuestionTextForTracking: questionText
      });
      trackJobFormQuestionInitiated(this.oneflareAnalytics, 'withJobFormController:trackQuestionInitiated', {
        jobFormCategoryId: this.state.jobFormCategoryId,
        jobFormCategoryName: this.state.jobFormCategoryName,
        questionId,
        jobFormStep,
        questionText
      } as TrackJobFormQuestionInitiatedOptions);
    }; 

    trackingHelper: TrackingHelper;
    private oneflareAnalytics: OneflareAnalytics;
    private jobFormService: JobFormService;
    private DOMAIN: 'oneflare' | 'wedding';

    render() {
      const props = { ...this.props };
      return (
        <JobFormControllerContext.Provider value={{ controller: this }}>
          <WrappedComponent {...props} />
          <JobForm />
        </JobFormControllerContext.Provider>
      );
    }
  };
};

export default withJobFormController;
