import { ApolloProvider } from '@apollo/client';
import { GoogleTagManager } from '@next/third-parties/google';
import AlertContainer from '@oneflare/flarekit/lib/components/AlertContainer';
import type { NextPage } from 'next';
import type { AppProps } from 'next/app';
import dynamic from 'next/dynamic';
import type { NextRouter } from 'next/router';
import { useRouter } from 'next/router';
import type { ReactElement } from 'react';
import { useEffect, memo } from 'react';

import { PageContext } from '@context-providers';
import { globalAlertContainer } from 'lib/alerts/globalAlertContainer';
import { useApollo } from 'lib/apollo/withApolloClient';
import { DataDogRumAgent } from 'lib/datadog/initializeDatadog';
import withJobFormController from 'lib/oneflare-job-form/withJobFormController';
import { publicRuntimeConfig } from 'lib/utils/Environment';
import AppWrapper from 'pages/AppWrapper';
import withReferer from 'shared/components/HighOrderComponent/withReferer';

import 'react-tooltip/dist/react-tooltip.css';

const ClientFeatureManagerSetupWrapper = dynamic(() => import('lib/features/ClientFeatureManagerSetupWrapper').then((module) => {
  return module.ClientFeatureManagerSetupWrapper;
}), {
  ssr: false
});

const OneflareAnalyticsPageTrackerWrapper = dynamic(() => import('lib/analytics/OneflareAnalyticsPageTrackerWrapper'), {
  ssr: false
});

type NextPageWithLayout<P = unknown, IP = P> = NextPage<P, IP> & {
  getLayout?: (page: ReactElement) => ReactElement;
  router: NextRouter;
};

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout;
};

const MyApp = ({ Component, pageProps }: AppPropsWithLayout) => {
  const apolloClient = useApollo(pageProps);
  const router = useRouter();
  const getLayout = Component.getLayout ?? ((page) => page);

  useEffect(() => {
    DataDogRumAgent.initializeAgent();
  }, []);

  return (
    <>
      <ApolloProvider client={apolloClient}>
        <OneflareAnalyticsPageTrackerWrapper />
        <ClientFeatureManagerSetupWrapper />
        <PageContext.Provider value={{ AlertContainer: globalAlertContainer }}>
          <AppWrapper>
            <AlertContainer ref={globalAlertContainer} />
            {getLayout(<Component {...pageProps} router={router} />)}
          </AppWrapper>
        </PageContext.Provider>
      </ApolloProvider>
      <GoogleTagManager gtmId={publicRuntimeConfig.GTM_ID} />
      {publicRuntimeConfig.DOMAIN === 'oneflare' && (
        <style jsx global>
          {`
            @font-face {
              font-display: swap;
              font-family: 'montserratlight';
              font-style: italic;
              font-weight: normal;
              src: url('https://cdn.oneflare.com/static/theme/2018/fonts/montserrat/montserrat-v14-latin-300italic.woff2')
                  format('woff2'),
                url('https://cdn.oneflare.com/static/theme/2018/fonts/montserrat/montserrat-v14-latin-300italic.woff')
                  format('woff');
            }

            @font-face {
              font-display: swap;
              font-family: 'montserratlight';
              font-style: normal;
              font-weight: normal;
              src: url('https://cdn.oneflare.com/static/theme/2018/fonts/montserrat/montserrat-v14-latin-300.woff2')
                  format('woff2'),
                url('https://cdn.oneflare.com/static/theme/2018/fonts/montserrat/montserrat-v14-latin-300.woff')
                  format('woff');
            }

            @font-face {
              font-display: swap;
              font-family: 'montserratregular';
              font-style: normal;
              font-weight: normal;
              src: url('https://cdn.oneflare.com/static/theme/2018/fonts/montserrat/montserrat-v14-latin-regular.woff2')
                  format('woff2'),
                url('https://cdn.oneflare.com/static/theme/2018/fonts/montserrat/montserrat-v14-latin-regular.woff')
                  format('woff');
            }

            @font-face {
              font-display: swap;
              font-family: 'montserratregular';
              font-style: italic;
              font-weight: normal;
              src: url('https://cdn.oneflare.com/static/theme/2018/fonts/montserrat/montserrat-v14-latin-italic.woff2')
                  format('woff2'),
                url('https://cdn.oneflare.com/static/theme/2018/fonts/montserrat/montserrat-v14-latin-italic.woff')
                  format('woff');
            }

            @font-face {
              font-display: swap;
              font-family: 'montserratbold';
              font-style: normal;
              font-weight: bold;
              src: url('https://cdn.oneflare.com/static/theme/2018/fonts/montserrat/montserrat-v14-latin-700.woff2')
                  format('woff2'),
                url('https://cdn.oneflare.com/static/theme/2018/fonts/montserrat/montserrat-v14-latin-700.woff')
                  format('woff');
            }

            @font-face {
              font-display: swap;
              font-family: 'montserratbold';
              font-style: italic;
              font-weight: bold;
              src: url('https://cdn.oneflare.com/static/theme/2018/fonts/montserrat/montserrat-v14-latin-700italic.woff2')
                  format('woff2'),
                url('https://cdn.oneflare.com/static/theme/2018/fonts/montserrat/montserrat-v14-latin-700italic.woff')
                  format('woff');
            }
          `}
        </style>
      )}
    </>
  );
};

export default memo(withReferer(withJobFormController(MyApp)));
